import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  FinanceReport,
  FinanceBudget,
  FinanceBudgetByYear,
  BalanceSheet,
  DashboardReport,
  InvoiceAgent,
} from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('ReportsService')

/**
 * Đây là services dành cho ReportsService
 */
@Injectable({ providedIn: 'root' })
export class ReportsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    report: 'finance/finance_report',
    budget_update: 'finance/finance_action/budget_update',
    budget: 'finance/finance_report_budget',
    new_budget: 'finance/budget_report_entity',
    key_indicators_month: 'finance/key_indicators_month',
    key_indicators_day: 'finance/key_indicators_day',
    add_note_key_indicators_day: 'finance/key_indicators_day/add_notes',
    add_note_key_indicators_month: 'finance/key_indicators_month/add_notes',
    add_note_budget: 'finance/budget_report_entity/add_notes',
    balance_sheet: 'report/balance_sheet',
    dashboard: 'report/dashboard_data',
    agents: 'report/agent_balance_total',
    report_generator: 'business/report_generator_business',
    report_sendmail: 'business/report_generator_business/send_email',
    key_indicators: 'finance/finance_report_key_indicators',
    key_indicators_bg: 'finance/finance_report_budget_key_indicators',
    key_indicators_actual: 'finance/finance_report_actual_key_indicators',
    key_indicators_budget: 'finance/finance_report_budget_key_indicators',
    b2a: 'finance/finance_report_b2a',
    variance_yoy: 'finance/finance_report_variance_yoy',
    variance_actual_budget: 'finance/finance_report_variance_actual_budget',
    pl: 'finance/finance_report_profit_and_loss',
    pl_update: 'finance/finance_report_profit_and_loss/update_actual_month',
    forecast_wizard: 'finance/finance_report_forecast_wizard',
    fixed_asset: 'finance/finance_report_fixed_asset',
    bob_pickup: 'finance/finance_report_bob_and_pickup',
    list_report_comment_generator: 'business/list_report_comment_generator',
    add_new_comment: 'business/report_generator_business/add_new_comment',
    occupancy_on_the_books: 'report/occupancy_on_the_books',
    sales_revenue_report: {
      list: 'report/srr_entity',
      add_comment: 'report/srr_entity/add_comment',
      sendmail: 'report/srr_entity/send_mail',
    },
    bank: {
      list: 'report/bank_entity',
      detail: 'report/bank_entity',
    },
    statutory_report: 'report/statutory_report',
    ar_register: {
      ar_register_list: 'report/ar_register',
      ar_outstanding_invoice: 'reservations/outstanding_invoice',
      ar_outstanding_invoice_generator: 'reservations/invoice_generator_entity',
      filter_lt_resident: 'business/filter_lt_resident',
      filter_hr: 'business/filter_ar_other',
      update_agent_invoice: 'reservations/outstanding_agent_invoice',
      ar_agent_invoice_generator:
        'reservations/invoice_generator_agent_corporate_data',
      update_unit_invoice: 'reservations/outstanding_invoice_unpaid',
      ar_unit_invoice_ltr_uom: 'reservations/invoice_generator_ltr_uom_data',
      send_email: 'reservations/outstanding_invoice/send_mail',
      ar_send_email: 'reservations/invoice_generator_entity/send_mail',
      filter_agent: 'business/filter_agent',
      filter_unit_owner: 'business/filter_unit_owner',
      update_status_export: 'reservations/outstanding_invoice/export',
      ar_bill_to: 'reservations/invoice_generator_entity/update_bill_to',
      create_tax_ar_invoice:
        'reservations/outstanding_invoice/create_tax_invoice',
      create_tax_ar_invoice_generator:
        'reservations/invoice_generator_entity/update_bill_to',
      export_pdf_ar: 'reservations/invoice_generator_entity/export_pdf',
      exportPDF_list_ar:
        'reservations/invoice_generator_entity/export_list_pdf',
    },
    daily_export_transaction: 'manager/daily_export_transactions',
    daily_export_restaurant_pos: 'manager/daily_export_restaurant_pos',
    unit_owner_operation_reports: 'report/unit_owner_operation_reports',
    unit_owner_report_comment:
      'report/unit_owner_operation_reports/add_comment',
    send_mail_unit_owner: 'report/unit_owner_operation_reports/send_mail',
    night_audit: {
      night_audit_reports: 'report/night_audit_reports',
      night_audit_reports_charges_analysis:
        'report/night_audit_reports_charges_analysis',
      night_audit_reports_cash_transactions:
        'report/night_audit_reports_cash_transactions',
      night_audit_reports_shift_balance:
        'report/night_audit_reports_shift_balance',
      night_audit_reports_promotion_usage:
        'report/night_audit_reports_promotion_usage',
      night_audit_reports_sales_analysis:
        'report/night_audit_reports_sales_analysis',
      night_audit_reports_tax_service_charge:
        'report/night_audit_reports_tax_service_charge',
      night_audit_reports_property_statistics:
        'report/night_audit_reports_property_statistics',
      night_audit_reports_sales_analysis_ltr_uom:
        'report/night_audit_reports_sales_analysis_ltr_uom',
      night_audit_reports_tax_service_charge_ltr_uom:
        'report/night_audit_reports_tax_service_charge_ltr_uom',
      night_audit_reports_property_statistics_ltr_uom:
        'report/night_audit_reports_property_statistics_ltr_uom',
      night_audit_reports_fab_menu_item_sales_summary:
        'report/night_audit_reports_fab_menu_item_sales_summary',
      night_audit_reports_fab_menu_item_sales_details:
        'report/night_audit_reports_fab_menu_item_sales_details',
      night_audit_reports_fab_menu_item_cost:
        'report/night_audit_reports_fab_menu_item_cost',
      night_audit_reports_fdo_menu_item_sales_summary:
        'report/night_audit_reports_fdo_menu_item_sales_summary',
      night_audit_reports_fdo_menu_item_sales_details:
        'report/night_audit_reports_fdo_menu_item_sales_details',
      send_mail: 'report/night_audit_reports/send_mail',
      night_audit_reports_export_pdf: 'report/night_audit_reports_export_pdf',
      night_audit_reports_export_excel:
        'report/night_audit_reports_export_excel',
    },
    cash_position: 'report/cash_position_entity',
    daily_revenue_report: 'report/daily_revenue',
    monthly_revenue_report: 'report/monthly_revenue',
    custom_guest_reports: 'report/guest_report',
    daily_payment_gateway_report: 'report/payment_gateway_daily_revenue',
    daily_payment_gateway_list: 'payment_gateways',
    export_occupancy_on_the_books: 'report/occupancy_on_the_books/export_pdf',
    detail_ar_invoice_generator: 'reservations/invoice_generator_entity',
    ar_invoice_hidden_tax: 'taxes/taxes_entity/add_hidden_entity',
    copy_to_next_year: 'finance/budget_report_entity/copy_to_next_year',
    check_existed: 'finance/budget_report_entity/check_existed_data_next_year',
    report_generator_email_settings: 'business/operations_report_emails_settings',
  }

  /**
   * Lấy tất cả Finance Report theo năm
   */
  getFinanceReport(year, month = '1', params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
      .set('month', month)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.report, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDailyRevenueReport(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.daily_revenue_report, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getMonthlyRevenueReport(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.monthly_revenue_report, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDailyPaymentGateWayReport(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.daily_payment_gateway_report, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDailyPaymentGateWayListReport(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.daily_payment_gateway_list, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy tất cả BoB Pickup theo năm
   */
  getBoBPickupReport(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.bob_pickup, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Cập nhật Budget
   * @param res Thông tin Budget
   */
  updateBudget(year, res: FinanceBudget[]): Observable<any> {
    const bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .post(`${this._apiPath.budget_update}/${bid}/${year}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy tất cả Finance Report theo năm
   */
  getFinanceBudget(year, params?: any): Observable<FinanceBudgetByYear[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<FinanceBudgetByYear[]>(this._apiPath.budget, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy tất cả Finance Report theo năm
   */
  getFinanceBudgetByYear(year, params?: any): Observable<FinanceBudget[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<FinanceBudget[]>(this._apiPath.budget, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getKeyIReportByMonth(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.key_indicators_month, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getKeyIReportByDay(year, month, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
      .set('month', month)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.key_indicators_day, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả Finance Report theo năm
   */
  getNewFinanceBudgetByYear(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.new_budget, pa).pipe(
      map(data => {
        log.debug('NEW BUDGET BY YEAR', data)
        return data
      })
    )
  }

  onSaveNewBudget(res): Observable<any> {
    return this.apiService.post(this._apiPath.new_budget, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy Balance Sheet
   * @param date Ngày
   */
  getBalanceSheet(date): Observable<BalanceSheet> {
    const pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)

    return this.apiService
      .get<BalanceSheet>(this._apiPath.balance_sheet, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy Balance Sheet
   * @param date Ngày
   */
  getReportDashboard(year): Observable<DashboardReport> {
    const pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)

    return this.apiService
      .get<DashboardReport>(this._apiPath.dashboard, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReportAgents(year): Observable<DashboardReport> {
    const pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)

    return this.apiService.get<DashboardReport>(this._apiPath.agents, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getReportGenerator(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.report_generator, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  sendmail(res): Observable<any> {
    return this.apiService.post(this._apiPath.report_sendmail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  sendmailUnitOwner(res): Observable<any> {
    return this.apiService.post(this._apiPath.send_mail_unit_owner, res).pipe(
      map(data => {
        return data
      })
    )
  }

  sendmailSrr(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.sales_revenue_report.sendmail, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  unitOwnerReportAddcomment(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.unit_owner_report_comment, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getKeyIndicators(year, month?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (month) {
      pa.set('month', month)
    }
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.key_indicators, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getKeyIndicatorsBG(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.key_indicators_bg, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getKeyIndicatorsActual(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.key_indicators_actual, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getKeyIndicatorsBudget(year, month?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (month) {
      pa.set('month', month)
    }
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.key_indicators_budget, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getB2A(year, params?: any): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.b2a, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getVarianceYoY(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.variance_yoy, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getVarianceActualBudget(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.variance_actual_budget, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getPLData(year, month, params?: any): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
      .set('month', month)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.pl, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  updatePLActual(year, month, res: any[]): Observable<any[]> {
    const bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .post<any[]>(`${this._apiPath.pl_update}/${bid}/${year}/${month}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getForecastWizard(year, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.forecast_wizard, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getFixedAssetReport(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(key => {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.fixed_asset, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllCommentReport(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.list_report_comment_generator, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  addComment(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_new_comment, res).pipe(
      map(data => {
        return data
      })
    )
  }
  addNoteBudgetGeneratorReport(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_note_budget, res).pipe(
      map(data => {
        return data
      })
    )
  }

  addNoteKeyIGeneratorReport(res, type): Observable<any> {
    const url =
      type == 1
        ? this._apiPath.add_note_key_indicators_day
        : this._apiPath.add_note_key_indicators_month
    return this.apiService.post(url, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * List Sales Revenue Report
   */
  srrList(manually, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('options[fieldsorderby][srr_sdate][value]', 'DESC')
      .set('manually', manually)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.sales_revenue_report.list, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Get Result Report
   */
  getResultReport(id, params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(`${this._apiPath.sales_revenue_report.list}/${id}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  generateResult(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.sales_revenue_report.list, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  chargesAnalysis(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_charges_analysis,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  cashTransactions(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_cash_transactions,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  shiftBalance(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.night_audit.night_audit_reports_shift_balance, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  promotionUsage(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_promotion_usage,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  salesAnalysis(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_sales_analysis,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  taxServiceChargeDetails(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_tax_service_charge,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  propertyStatistics(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_property_statistics,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  ltrUomSalesAnalysis(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_sales_analysis_ltr_uom,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  ltrUomTaxServiceChargeDetails(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit
          .night_audit_reports_tax_service_charge_ltr_uom,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  ltrUomPropertyStatistics(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit
          .night_audit_reports_property_statistics_ltr_uom,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  FBMenuItemSalesSummary(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit
          .night_audit_reports_fab_menu_item_sales_summary,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  FBMenuItemSalesDetails(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit
          .night_audit_reports_fab_menu_item_sales_details,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  FBMenuItemCosts(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit.night_audit_reports_fab_menu_item_cost,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  FDMenuItemSalesSummary(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit
          .night_audit_reports_fdo_menu_item_sales_summary,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  FDMenuItemSalesDetails(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.night_audit
          .night_audit_reports_fdo_menu_item_sales_details,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  exportPdf(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.night_audit.night_audit_reports_export_pdf, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  exportExcel(day, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('day', day)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.night_audit.night_audit_reports_export_excel, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  sendMailNightAuditReport(res): Observable<any> {
    return this.apiService.post(this._apiPath.night_audit.send_mail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  addCommentSrr(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.sales_revenue_report.add_comment, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getListBank(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.bank.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createBank(res): Observable<any> {
    return this.apiService.post(this._apiPath.bank.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createCashPosition(res): Observable<any> {
    return this.apiService.post(this._apiPath.cash_position, res).pipe(
      map(data => {
        return data
      })
    )
  }
  getListReportsCashPosition(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.cash_position, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteBank(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.bank.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  editBank(id, res): Observable<any> {
    return this.apiService.put(`${this._apiPath.bank.detail}/${id}`, res).pipe(
      map(data => {
        return data
      })
    )
  }
  getStatutoryReport(
    fdate?,
    tdate?,
    date_type?,
    status?,
    guest_room?,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('fdate', fdate)
      .set('tdate', tdate)
      .set('date_type', date_type)
      .set('status', status)
      .set('guest_room', guest_room)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.statutory_report, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getARRegister(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get(this._apiPath.ar_register.ar_register_list, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getARInvoice(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('ar_invoice', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.ar_register.ar_outstanding_invoice_generator, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createInvoice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.ar_register.ar_outstanding_invoice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createAgentInvoice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.ar_register.update_agent_invoice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  filterLongTerm(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('get_unit', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.ar_register.filter_lt_resident, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  filterHR(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.ar_register.filter_hr, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  filterAgent(type?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('type', type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.ar_register.filter_agent, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  filter_unit_owner(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('get_unit', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.ar_register.filter_unit_owner, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getDataAgent(
    uid?,
    year?,
    month?,
    client_type?,
    include_unpaid?,
    unit_setting?,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('year', year)
      .set('month', month)
      .set('client_type', client_type)
      .set('include_unpaid', include_unpaid)
      .set('unit_setting', unit_setting)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.ar_register.ar_agent_invoice_generator, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getDataUnit(
    uid?,
    year?,
    month?,
    client_type?,
    include_unpaid?,
    unit_setting?,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('year', year)
      .set('month', month)
      .set('client_type', client_type)
      .set('include_unpaid', include_unpaid)
      .set('unit_setting', unit_setting)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.ar_register.ar_unit_invoice_ltr_uom, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<InvoiceAgent> {
    return this.apiService
      .get<InvoiceAgent>(
        `${this._apiPath.ar_register.ar_outstanding_invoice}/${id}`
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getDetailARInvoiceGenerator(id: string): Observable<InvoiceAgent> {
    return this.apiService
      .get<InvoiceAgent>(`${this._apiPath.detail_ar_invoice_generator}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  deleteOutstandingInvoice(id): Observable<any> {
    return this.apiService
      .delete(
        `${this._apiPath.ar_register.ar_outstanding_invoice_generator}/${id}`
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }
  update_agent_invoice(id, res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.ar_register.update_agent_invoice}/${id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  update_oustanding_invoice(id, res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.ar_register.ar_outstanding_invoice}/${id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  sendmailAr(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.ar_register.ar_send_email, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  update_status_export(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.ar_register.update_status_export, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDailyExportTransaction(date): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    log.debug('xxxxxxx', date)
    return this.apiService
      .get<any[]>(this._apiPath.daily_export_transaction, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getUnitOwnerOperationReport(params?): Observable<any[]> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.unit_owner_operation_reports, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDailyExportRestaurantPos(date): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    log.debug('xxxxxxx', date)
    return this.apiService
      .get<any[]>(this._apiPath.daily_export_restaurant_pos, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  downloadFile(data, column = [], filename = 'data') {
    let csvData = this.ConvertToCSV(data, column)
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    })
    let dwldLink = document.createElement('a')
    let url = URL.createObjectURL(blob)
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank')
    }
    dwldLink.setAttribute('href', url)
    dwldLink.setAttribute('download', filename + '.csv')
    dwldLink.style.visibility = 'hidden'
    document.body.appendChild(dwldLink)
    dwldLink.click()
    document.body.removeChild(dwldLink)
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
    let str = ''
    let row = ''

    // for (let index in headerList) {
    //   row += headerList[index] + ',';
    // }
    // row = row.slice(0, -1);
    // str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = ''
      for (let index in headerList) {
        let head = headerList[index]
        line += Number(index) > 0 ? '|' + array[i][head] : array[i][head]
      }
      str += line + '\r\n'
    }
    return str
  }

  /**
   * Ledger Movements
   */
  getLedgerMovements(): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    return this.apiService
      .get<any>(this._apiPath.night_audit.night_audit_reports, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createBillTo(res): Observable<any> {
    return this.apiService.post(this._apiPath.ar_register.ar_bill_to, res).pipe(
      map(data => {
        return data
      })
    )
  }
  createTaxINvoiceAR(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.ar_register.create_tax_ar_invoice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createARTaxInvoice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.ar_register.create_tax_ar_invoice_generator, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  exportAR_PDF(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.ar_register.export_pdf_ar,
      res,
      null,
      null,
      'blob'
    )
  }

  exportPDFListARRegister(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.ar_register.exportPDF_list_ar,
      res,
      null,
      null,
      'blob'
    )
  }

  getOccupancyOnTheBooks(year, month, params?: any): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
      .set('month', month)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.occupancy_on_the_books, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getCustomGuestReports(
    start?,
    end?,
    type?,
    sort?,
    include?,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('start', start)
      .set('end', end)
      .set('type', type)
      .set('sort', sort)
      .set('include', include)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.custom_guest_reports, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  exportPDFOccupancy(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.export_occupancy_on_the_books,
      res,
      null,
      null,
      'blob'
    )
  }
  arInvoiceHiddenTax(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.ar_invoice_hidden_tax, res).pipe(
      map(data => {
        return data
      })
    )
  }

  budgetCopyAndPassData(res): Observable<any> {
    return this.apiService.post(this._apiPath.copy_to_next_year, res).pipe(
      map(data => {
        return data
      })
    )
  }

  checkExistedBudget(res): Observable<any> {
    return this.apiService.post(this._apiPath.check_existed, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getReportGeneratorEmailSettings(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.report_generator_email_settings}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  onCreateReportGeneratorEmailSettings(res): Observable<any> {
    return this.apiService.post(this._apiPath.report_generator_email_settings, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
