import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { BlockRoom } from '../../../models'

const log = new Logger('BlockRoomService')

/**
 * Đây là services dành cho BlockRoom
 */
@Injectable({ providedIn: 'root' })
export class BlockRoomService {
  constructor(private apiService: ApiService) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'procurement/asset_types_entity',
    detail: 'room/blocked_entity',
    confirm_block: 'room/blocked_entity/create_reservation_from_blocked',
  }

  // getAll(params?: any): Observable<BlockRoom[]> {
  //   // let pa = new HttpParams();
  //   let pa = new HttpParams().set(
  //     "parameters[asset_type_business_id]",
  //     this.storage.getActiveLocalBusinessID()
  //   );
  //   if (params) {
  //     Object.keys(params).forEach(function(key) {
  //       pa = pa.append(key, params[key]);
  //     });
  //   }
  //   return this.apiService.get<BlockRoom[]>(this._apiPath.list, pa).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }

  /**
   * Lấy thông tin chi tiết của BlockRoom dưạ vào @id
   * @param Id ID của BlockRoom
   */
  getDetail(id: string): Observable<BlockRoom> {
    return this.apiService.get<BlockRoom>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo BlockRoom
   * @param res Thông tin BlockRoom
   */
  create(res: BlockRoom): Observable<any> {
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin BlockRoom
   *
   * @param res Thông tin BlockRoom muốn chỉnh sửa
   */
  edit(res: BlockRoom): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.bid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin BlockRoom
   *
   * @param id Xóa Season bằng BlockRoom ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  confirmBlock(res): Observable<any> {
    return this.apiService.post(this._apiPath.confirm_block, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
